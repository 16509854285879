
<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="900">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            View Invoice No {{ modalInfo.eInvoiceNumber }}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billID"
                        label="E-Bill ID"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billNo"
                        label="E-Bill No"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.supplier"
                        label="Supplier"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.tin"
                        label="Tax Identification No. (TIN)"
                        disabled
                        hide-details="auto"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.total1"
                        label="Unit Price (RM)"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.sst"
                        label="SST (RM)"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.finalTotal"
                        label="Total Amount (RM)"
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.type"
                        label="E-Bill Type"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billDate"
                        label="E-Bill Date"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.receivedDate"
                        label="Sent Date"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.submissionID"
                        label="Submission ID"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.status"
                        label="Status"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.error"
                        label="Error"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.cancel"
                        label="Cancel"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import alertbox2 from './Alert';
import {rule} from '../../assets/js/checkPerm';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            forms:{
                carpark:'N/A',
                billNo:'N/A',
                sentDate:'N/A',
                billID:'N/A',
                supplier:'N/A',
                tin:'N/A',
                total1:'N/A',
                sst:'N/A',
                finalTotal:'N/A',
                billDate:'N/A',
                billRef: 'N/A',
                receivedDate: 'N/A',
                sentLHDN: 0,
                cancel: 0,
                submissionID: 'N/A',
                type: 'N/A',
                status: 'N/A',
                error: 'N/A',
            },

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            searchlocation: '',
            componentloader: false,
            hasValidated: false
        }
    },

    components: {alertbox, alertbox2},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },

        'markers.position': {
        handler: function(value) {
            this.form[6].baylatitude = value.lat;
            this.form[7].baylongitude = value.lng;
        },
        },
    },

    methods: {

        execFilter(typ){
            let self = this;

            if (typ == 'operator'){
                this.form[2].carpark = [];
                this.form[2].selected = '';

                if (self.form[1].selected){
                    let itm = this.form[2].itemsFull.filter(i => i.OperatorID == self.form[1].selected);

                    this.form[2].carpark.push({name: 'Select Car Park', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form[2].carpark.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    this.form[2].carpark = this.form[2].itemsFull;
                }
            }
        },



        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.OperatorID.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },
        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async beforeCreateForm(flag){
            this.form[2].rule = [
                v => !!v || 'This is required',
            ];

            this.form[3].rule = [
                v => !!v || 'This is required',
            ];

            this.form[4].rule = [
                v => !!v || 'This is required',
            ];

            this.form[5].rule = [
                v => !!v || 'This is required',
            ];

            this.form[6].rule = [
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form[7].rule = [
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter an email address',
            ];

            this.form[8].rule = [
                v => !!v || 'This is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.componentloader = true;
            this.searchlocation = "";
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.componentloader = false;
                this.$emit('closed', false);
            }
        },
        handleDelete(value){
            console.log(value)
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        async handleValue(){
            console.log(this.modalInfo)

            this.forms.carpark = this.modalInfo.CarParkID ?  this.modalInfo.CarParkID +' - '+ this.modalInfo.CarParkName : this.forms.carpark;
            this.forms.billNo = this.modalInfo.eInvoiceNumber;
            this.forms.sentDate = this.modalInfo.createdAt;
            this.forms.billID = this.modalInfo.id;
            this.forms.supplier = this.modalInfo.Supplier.id +' - '+ this.modalInfo.Supplier.name;
            this.forms.tin = this.modalInfo.Supplier.tin;
            this.forms.total1 = Number(this.modalInfo.Document.total1).toFixed(2);
            this.forms.sst = Number(this.modalInfo.Document.total2).toFixed(2);
            this.forms.finalTotal = Number(this.modalInfo.Document.finalTotal).toFixed(2);
            this.forms.billDate = this.modalInfo.createdAt;
            this.forms.receivedDate = this.modalInfo.Document.createdAt;
            this.forms.cancel = this.modalInfo.isCancel ? 'Yes' : 'No';
            this.forms.submissionID = this.modalInfo.Document.submissionId ?? this.forms.submissionID;
            this.forms.type = this.modalInfo.Document.Constraints_InvoiceType.name ?? this.forms.type;
            this.forms.status = this.modalInfo.Document.status ?? this.forms.status;
            this.forms.error = this.modalInfo.Document.error ?? this.forms.error;
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }
                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
                this.hasValidated = false;

            }
        },
        
        async validateTin(){

            const response = await this.$store.dispatch('validateTin', {idType: this.form[4].selected, idValue: this.form[9].idno, tin: this.form[8].tinno});
            if(response.data.record == true){
                this.hasValidated = true;
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `Success: Tax Identification No. validated!`;
            } else {
                this.hasValidated = false;
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = `Parameter Error: Invalid Detail!`;
            }
        },
    }
}
</script>