
<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="900">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            View Bill ID {{ modalInfo.Bill.id }}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.carpark"
                        label="Car Park"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billNo"
                        label="Bill No"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.sentDate"
                        label="Sent Date"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billID"
                        label="Bill ID"
                        disabled
                        hide-details="auto"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.supplier"
                        label="Supplier"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.tin"
                        label="Tax Identification No. (TIN)"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.total1"
                        label="Unit Price (RM)"
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.sst"
                        label="SST (RM)"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.finalTotal"
                        label="Total Amount (RM)"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billDate"
                        label="Bill Date"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.billRef"
                        label="Bill Ref"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.receivedDate"
                        label="Received Date"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.sentLHDN"
                        label="Sent LHDN"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                
                

                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.submissionID"
                        label="Submission ID"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="forms.cancel"
                        label="Cancel"
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import alertbox2 from './Alert';
import {rule} from '../../assets/js/checkPerm';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {system: [], itemsFull: [], rule: [], selected: '' }, // 0
                {operator: [], itemsFull: [], rule: [], selected: '' }, // 1
                {carpark: [], itemsFull: [], rule: [], selected: '' }, // 2
                {companyname: '', rule: [] }, // 3
                {idtype: [], rule: [], selected: '2' }, // 4
                {phonenumber: '', rule: [], }, // 5
                {sstno: '', rule: [] }, // 6
                {email: '', rule: [] }, // 7
                {tinno: '', rule: [] }, // 8
                {idno: '', rule: [] }, // 9
                {msic: [], rule: [], selected: '' }, // 10
                {state: [], rule: [], selected: '' }, // 11
                {country: [], rule: [], selected: '' }, // 12
                {address1: '', rule: [] }, // 13
                {address2: '', rule: [] }, // 14
                {address3: '', rule: [] }, // 15
                {postal: '', rule: [] }, // 16
                {city: '', rule: [] }, // 17
                {supplierid: '', rule: [] }, // 18
                {status: 0, rule: [] }, // 19
            ],
            forms:{
                carpark:'N/A',
                billNo:'N/A',
                sentDate:'N/A',
                billID:'N/A',
                supplier:'N/A',
                tin:'N/A',
                total1:'N/A',
                sst:'N/A',
                finalTotal:'N/A',
                billDate:'N/A',
                billRef: 'N/A',
                receivedDate: 'N/A',
                sentLHDN: 0,
                cancel: 0,
                submissionID: 'N/A'

            },

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            searchlocation: '',
            componentloader: false,
            hasValidated: false
        }
    },

    components: {alertbox, alertbox2},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },

        'markers.position': {
        handler: function(value) {
            this.form[6].baylatitude = value.lat;
            this.form[7].baylongitude = value.lng;
        },
        },
    },

    methods: {
        async getAllOperator(){
            // console.log(this.allInfo)
            const response = await this.$store.dispatch("listOperator", {search: ''});
            const r = response.data.record.filter((elem) => !this.allInfo.find(({ OperatorID }) => elem.OperatorID === OperatorID && OperatorID != this.modalInfo.OperatorID));

            const operator = [];
            for(var i=0; i<r.length; i++){
                if (r[i].SystemID === 2){
                    r[i].name = `${r[i].OperatorID} - ${r[i].OperatorName}`;
                    operator.push({name: r[i].name, OperatorID: r[i].OperatorID});
                }
            }
            this.form[1].operator = r;
            this.form[1].itemsFull = r;
        },

        execFilter(typ){
            let self = this;

            if (typ == 'operator'){
                this.form[2].carpark = [];
                this.form[2].selected = '';

                if (self.form[1].selected){
                    let itm = this.form[2].itemsFull.filter(i => i.OperatorID == self.form[1].selected);

                    this.form[2].carpark.push({name: 'Select Car Park', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form[2].carpark.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    this.form[2].carpark = this.form[2].itemsFull;
                }
            }
        },

        async getAllIdentifierList(){
            let response = await this.$store.dispatch("lhdnIdentifierList");
            this.form[4].idtype = response.data.record;
        },
        
        async getAllMsicList(){
            let response = await this.$store.dispatch("lhdnMsicList");
            response.data.record.map((i)=> i.fullName = `${i.id} - ${i.code}: ${i.name}`);
            this.form[10].msic = response.data.record;
        },
        async getAllStateList(){
            let response = await this.$store.dispatch("lhdnStateList");
            this.form[11].state = response.data.record;
        },
        async getAllCountryList(){
            let response = await this.$store.dispatch("lhdnCountryList");
            this.form[12].country = response.data.record;
        },


        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.OperatorID.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        onRuleChange() {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
            this.form[3].rule = [];
            this.form[4].rule = [];
            this.form[5].rule = [];
            this.form[6].rule = [];
            this.form[7].rule = [];
            this.form[8].rule = [];
            this.form[9].rule = [];
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async beforeCreateForm(flag){
            this.form[2].rule = [
                v => !!v || 'This is required',
            ];

            this.form[3].rule = [
                v => !!v || 'This is required',
            ];

            this.form[4].rule = [
                v => !!v || 'This is required',
            ];

            this.form[5].rule = [
                v => !!v || 'This is required',
            ];

            this.form[6].rule = [
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form[7].rule = [
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter an email address',
            ];

            this.form[8].rule = [
                v => !!v || 'This is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.componentloader = true;
            this.searchlocation = "";
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.componentloader = false;
                this.$emit('closed', false);
            }
        },
        handleDelete(value){
            console.log(value)
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        async handleValue(){
            console.log(this.modalInfo)
            this.onRuleChange();
            this.getAllOperator();
            this.getAllIdentifierList();
            this.getAllMsicList();
            this.getAllStateList();
            this.getAllCountryList();

            this.hasValidated = true;

            this.forms.carpark = this.modalInfo.CarParkID ?  this.modalInfo.CarParkID +' - '+ this.modalInfo.CarParkName : this.forms.carpark;
            this.forms.billNo = this.modalInfo.Bill.billNo;
            this.forms.sentDate = this.modalInfo.createdAt;
            this.forms.billID = this.modalInfo.Bill.id;
            this.forms.supplier = this.modalInfo.Supplier.id +' - '+ this.modalInfo.Supplier.name;
            this.forms.tin = this.modalInfo.Supplier.tin;
            this.forms.total1 = this.modalInfo.total1;
            this.forms.sst = this.modalInfo.total2;
            this.forms.finalTotal = this.modalInfo.finalTotal;
            this.forms.billDate = this.modalInfo.invoiceDate;
            this.forms.billRef = this.modalInfo.billRefId ?? this.forms.billRef;
            this.forms.receivedDate = this.modalInfo.createdAt;
            this.forms.sentLHDN = this.modalInfo.isSent ? 'Yes' : 'No';
            this.forms.cancel = this.modalInfo.isCancel ? 'Yes' : 'No';
            this.forms.submissionID = this.modalInfo.submissionId ?? this.forms.submissionID;
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }
                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
                this.hasValidated = false;

            }
        },
        
        async validateTin(){

            const response = await this.$store.dispatch('validateTin', {idType: this.form[4].selected, idValue: this.form[9].idno, tin: this.form[8].tinno});
            if(response.data.record == true){
                this.hasValidated = true;
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `Success: Tax Identification No. validated!`;
            } else {
                this.hasValidated = false;
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = `Parameter Error: Invalid Detail!`;
            }
        },
    }
}
</script>