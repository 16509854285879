<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Submission</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items.sync="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        item-key="ProfileID"
                    >
                    <template slot="item" slot-scope="props">
                        <tr :class="props.item.checkbox ? ['custom-selected'] : ''">
                            <!-- <td v-if="validateAccess('businesscpo_usersdelete')"><v-simple-checkbox v-model="props.item.checkbox"></v-simple-checkbox></td> -->
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.supplierId}}</td>
                            <td>{{props.item.name ? props.item.name : 'N/A'}}</td>
                            <td>{{props.item.billId}}</td>
                            <td>{{ props.item.Bill && props.item.Bill.billDocumentId ? props.item.Bill.billDocumentId : 'N/A'}}</td>
                            <td>{{props.item.Bill.billNo}}</td>
                            <td>{{props.item.Constraints_BillType.name}}</td>
                            <td>{{props.item.submissionId == null ? 'N/A' : props.item.submissionId}}</td>
                            <!-- <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="viewMore(props.item, 'update')" style="text-decoration: underline" id="custom-hover">{{props.item.name}}</a>
                                    </template>
                                    <span>Update Setup</span>
                                </v-tooltip>
                            </td> -->
                            <td>
                                <span>{{props.item.createdAt}}</span>
                            </td>
                            <td>
                                <span>{{props.item.updatedAt == null ? 'N/A' : props.item.updatedAt}}</span>
                            </td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.isSent, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.isSent, 'text')}}
                                </v-chip>
                            </td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.isCancel, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.isCancel, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <span v-if="validateAccess('businesscpo_usersdelete')">
                    <v-btn @click="viewDelete(items.filter(i => i.checkbox == true), 'delete')" v-if="items.some(i => i.checkbox == true)" class="mt-4" tile small color="error" type="button"><v-icon left x-small>fa fa-trash</v-icon> Remove Profile</v-btn>
                    </span>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <addprofile :dialog="ma.modal_profile" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></addprofile>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :category="ma.category" :issue="ma.issue" @confirm="onConfirm" @closed="onClosed"></create>
        <update :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" :category="ma.category" :issue="ma.issue" @confirm="onConfirm" @closed="onClosed" @delete="onDelete"></update>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import alert from '.././modals/AlertStatus';
import create from '.././modals/CreateSetup';
import update from '.././modals/UpdateSetup';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import alertdelete from '../modals/AlertDeleteProfile';
import addprofile from '.././modals/CreateProfileExistingCustomer';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'TaxSubmission',
    title: 'Tax Submission',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Finance',
                disabled: true,
                href: '#',
                },
                
                {
                text: 'EInvoice',
                disabled: true,
                href: '#',
                },

                {
                text: 'Submission',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            /*
            #, ID, User ID, Name, Email ID, Email, CPO ID, Operator, Profile ID, Role, Created Date, Last Login Date, Status :warning: 
            */
            headers: [
                //{ text: '', value: 'checbox', width: '3%' },
                { text: '#', value: 'row', width: '2%' },
                { text: 'Supplier ID', value: 'id', width: '2%' },
                { text: 'Supplier Name', value: 'name', width: '5%' },
                { text: 'Bill ID', value: 'name', width: '3%' },
                { text: 'Bill Doc. ID', value: 'name', width: '3%' },
                { text: 'Bill No', value: 'name', width: '3%' },
                { text: 'Bill Type', value: 'name', width: '3%' },
                { text: 'Submission ID', value: 'name', width: '3%' },
                { text: 'Created Date', value: 'createdAt', width: '3%' },
                { text: 'Updated Date', value: 'updatedAt', width: '3%' },
                { text: 'Sent Status', value: 'Status', width: '1%' },
                { text: 'Cancel Status', value: 'Status', width: '1%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_delete: false,
                modal_profile: false,
                readonly: true,
                allInfo: [],
                item: {}
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            dropitems: {},
            totalrecords: '',
            tableselected: [],
        }
    },

    components: {alert, alertbox2, alertdelete, addprofile, create, update},

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getUsers();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        }
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

            else{
                return '';
            }
        },
    },

    async created(){
        //await this.getHeaders();
        this.getSystemFull();
        await this.getOperatorFull();
        await this.getList(1);
        this.getUpdatedProfile();
    },

    methods: {
        async getUsers(pg){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getCustomersOperator", {resultpage: pg > 0 ? this.page = pg : this.page, search: this.search});
                let response3 = await this.$store.dispatch("getAllOperator", {search: ''});
                let response4 = await this.$store.dispatch("getAllCarpark2", {});
                let response5 = await this.$store.dispatch("listProfileTypeCustomer", {search: ''});
                let response6 = await this.$store.dispatch("listProfileRoleCustomer", {search: ''});
                let response7 = await this.$store.dispatch("getAllSystem2", {});

                let dt = response.data.record;
                let dt3 = response3.data.record;
                let dt4 = response4.data.record;
                let dt5 = response5.data.record;
                let dt6 = response6.data.record;
                let dt7 = response7.data.record;

                let recordCount = response.data.recordcount[0].resultcount;
                this.totalrecords = recordCount;

                let customersbytype = dt.filter(i => i.Type_Name.toLowerCase() === 'operator');
                for(var i=0; i<customersbytype.length; i++){
                        customersbytype[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                        customersbytype[i].OperatorUserName = customersbytype[i].OperatorUserName || 'Placeholder';
                        customersbytype[i].OperatorDetailCreatedDateTime = moment(new Date(customersbytype[i].OperatorDetailCreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                        customersbytype[i].lastLoginDate = customersbytype[i].lastLoginDate == null ? "N/A" : moment(new Date(customersbytype[i].lastLoginDate)).format('DD-MM-YYYY hh:mm:ss A');
                        customersbytype[i].OperatorDetailUpdatedDateTime = customersbytype[i].OperatorDetailUpdatedDateTime == null ? "N/A" : moment(new Date(customersbytype[i].OperatorDetailUpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                        customersbytype[i].checkbox = false;
                        customersbytype[i].LastLogin = "N/A";
                        customersbytype[i].Status = "N/A";
                        customersbytype[i].ID = "N/A";
                }

                this.items = customersbytype;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;

                this.dropitems.operator = dt3;
                this.dropitems.carpark = dt4;
                this.dropitems.profiletype = dt5;
                this.dropitems.profilerole = dt6;

                if (this.$route.query.action == 'profileremoval'){
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile has been deleted!`;
                    this.$router.replace({'query': null});
                }
                
            }

            catch(err){
                console.log(err);
            }
        },

        async getList(pg){
            this.tableloading = true;
            const response = await this.$store.dispatch("getLhdnSubmissionList", {page: pg > 0 ? this.page = pg : this.page, search : this.search })
            console.log(response.data)
            
            this.totalrecords = response.data.count;
            this.items = response.data.record;
            for(let i = 0; i<this.items.length; i++){
                this.items[i].createdAt = moment(this.items[i].createdAt).format('DD-MM-YYYY hh:mm:ss A');
                this.items[i].updatedAt = this.items[i].updatedAt != null ?  moment(this.items[i].updatedAt).format('DD-MM-YYYY hh:mm:ss A') : 'N/A';
            }
            this.pages = Math.ceil(this.totalrecords / 100);
            this.tableloading = false;

        },

        comparatorID(a, b) {
            let it = a.SystemID == b.SystemID;
            return it;
        },

        async getSystemFull(){
            let list = [];
            this.system.items = [];
            try{
                this.system.loader = true;
                this.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.system.items.push({name: "All Systems", SystemID: ""});
                    this.system.itemsFull.push({name: "All Systems", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;
                        this.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                this.system.selected = "";

                this.system.loader = false;
                this.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;

                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        getUpdatedProfile(){
            if (this.$route.query.action == "profileremoval"){
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `${ERR.HANDLE('AP000')}: Profile Deleted!`;
                this.$router.replace({'query': null});
            }

            if (this.$route.query.action == "createProfile"){
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `${ERR.HANDLE('AP000')}: Profile Updated!`;
                this.$router.replace({'query': null});
            }

            if (this.$route.query.action == "updateExistingProfile"){
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `${ERR.HANDLE('AP000')}: Profile Added!`;
                this.$router.replace({'query': null});
            }

            if (this.$route.query.action == "createUserAndProfile"){
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `${ERR.HANDLE('AP000')}: User and Profile Created!`;
                this.$router.replace({'query': null});
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        getHeaders(){
            if (!this.validateAccess('businesscpo_usersdelete'))
                this.headers.splice(0, 1);

            else
                this.headers;
        },

        async addUser(v){
            this.btn_loading = true;
            this.tableloading = true;

            try{
                this.swal.scolor = 'green';
                let req = {email: v[0].email};
                let response = await this.$store.dispatch("userRegister", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    let response2 = await this.$store.dispatch("userReset", req);
                    if (response2.data.code === 'AP000'){
                        await this.getList();
                        this.add(v);
                        this.ex.modal = true;
                        this.ex.content.headline = 'Email Registered!';
                        this.ex.content.icon = 'fa fa-check';
                        this.ex.content.chipColor = '#a0e8af';
                        this.ex.content.iconColor = 'green';
                        this.ex.content.maintext = 'Please inform user of this email to check their inbox/junk mail for verification link.';
                        this.ex.content.smalltext = 'If verification link is expired, please reset the same email again.';

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Email Registered!`;
                    }
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This email is already registered`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }

            /*
            try{
                this.swal.scolor = 'green';
                let req = {email: v[0].email};
                let response = await this.$store.dispatch("userRegister", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    let response2 = await this.$store.dispatch("userReset", req);
                    if (response2.data.code === 'AP000'){
                        await this.getUsers();
                        this.ex.modal = true;
                        this.ex.content.headline = 'Email Registered!';
                        this.ex.content.icon = 'fa fa-check';
                        this.ex.content.chipColor = '#a0e8af';
                        this.ex.content.iconColor = 'green';
                        this.ex.content.maintext = 'Please inform user of this email to check their inbox/junk mail for verification link.';
                        this.ex.content.smalltext = 'If verification link is expired, please reset the same email again.';

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Email Registered!`;
                    }
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This email is already registered`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
            */
        },

        async add(v){
            this.btn_loading = true;
            this.tableloading = true;

            let loop = v[1].selected.length;

            for(let i=0; i<loop; i++){
                try{
                    this.swal.scolor = 'green';
                    let req = {userid: this.items[this.items.length - 1].userID, type: v[2].selected, role: v[1].selected[i].ID};

                    let response = await this.$store.dispatch("createProfile", req);
                    await timer.sleep(800);
                    if (response.data.code === 'AP000'){
                            await this.getUsers();
                            this.swal.notification = true;
                            this.swal.scolor = 'green';
                            this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile Created!`;
                    }

                    else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: This profile already exists`;
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                    }

                    this.tableloading = false;
                    this.btn_loading = false;
                }

                catch(err){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = ERR.HANDLE(response.data.code);
                }
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'reset'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewDelete(row, flag){
            console.log(row);
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
            }

            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewCreate(){
            this.$router.push({ name: "Create User"});
        },


        onClosed(value){
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_delete = value;
                this.ma.modal_profile = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.modal = false;
                this.addLhdnSetup(value);
            }
            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.updateLhdnSetup(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_status = false;
                    this.userReset();
                }

                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_update = false;
                    // this.deleteProfile();
                }
            }
        },
        
        onDelete(){
            this.ma.modal_update = false;
            this.deleteLhdnSetup();
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getList(1);
          }, 800);
        },

        validateArr(arr){
            let truth = arr.some(item => {
                return item === true;
            });

            return truth;
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === true){ return 'Yes'}
                    else if (val === false){return 'No'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === true){ return 'red'}
                    else if (val === false){return 'green'}
                    else {return 'grey'}
            }
        },
    }
}
</script>